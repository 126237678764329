import React, { useState, useEffect } from "react";
import Popup from "./basepopup";
import moment from "moment";
import { joinContestApi, login } from "../../apis";
import url from "../../constants/url";
import { useNavigate } from "react-router-dom";
import { routehelp } from "../config/routehelp";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

import Loader from "../loader";
import { formatDate, formatDate2, formatTime } from "../Utilities/helper";
import { addTrackData, trackerEventId } from "../../config/tracker";

const ContestConfirmationPopup = ({
  isOpen,
  title,
  onClose,
  contest,
  selectedMatchDate,
  uniqueIgn,
  setIsContestConfirmationPopup,
  setErrorMsg,
  setShowToast,
}) => {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [isLoading, setIsLoader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    addTrackData(trackerEventId?.Contest_Join_Popup_Shown, {
      gameCatalogName: `${localStorage.getItem("selectedGameName")}`,
      gameCatalogId: `${localStorage.getItem("game")}`,
      contestId: `${contest?._id}`,
    });
  }, []);

  const fetchData = async () => {
    setIsLoader(true);
    setIsButtonDisable(true);
    let res = await login();
    if (res?.success) {
      setIsLoader(false);
      setIsButtonDisable(false);
      setCurrentBalance(res?.data?.walletBalance);
    }
    else {
      setIsLoader(false);
    }
  };


  const handlerJoin = async () => {
    setIsLoader(true);
    // setContestDisabled(true)
    let data = {
      joinedDate: new Date(),
      JoinedTimestamp: new Date().getTime(),
      contest: contest?._id,
      inGameUserName: uniqueIgn,
      user: JSON?.parse(localStorage?.getItem("profile"))?.user_id,
      indigg_access_token: localStorage?.getItem("accessToken"),
    };

    if (contest?.entryFee > currentBalance) {
      setIsLoader(false);
      setErrorMsg("Insufficient balance in your account.");
      setIsContestConfirmationPopup(false);
      setShowToast(true);
    } else {
      if (contest?.gameTypeAry?.players > 1) {
        // team registration
        data.players = contest?.gameTypeAry?.players
        data.extraPlayers = contest?.gameTypeAry?.extraPlayers
        data.game_type = contest?.gameTypeAry?._id
        localStorage.setItem("registerData", JSON.stringify(data))
        localStorage.setItem("registerType", "contest")
        navigate("/team-registration");
      }
      else {
        try {
          const res = await joinContestApi(data);
          if (res?.success) {
            setIsButtonDisable(false);
            navigate("/contest/details/" + contest?._id);
            setIsLoader(false);
          } else {
            setIsButtonDisable(false);
            setErrorMsg(res?.response?.data?.errors?.[0]?.msg || res?.data?.errors?.[0]?.msg);
            setIsContestConfirmationPopup(false);
            setShowToast(true);
            setIsLoader(false);
          }
        } catch (error) {
          setIsButtonDisable(false);
          setErrorMsg(error?.response?.data?.errors?.[0]?.msg);
          setIsContestConfirmationPopup(false);
          setShowToast(true);
          setIsLoader(false);
        }
      }
    }
  };

  return (
    isLoading
    ?
    <Loader/> 
    :
    <>
      <Popup isOpen={isOpen} title={contest?.title} onClose={() => onClose(false)}>
        <div className="superpower-team-heading">
          <div className="joingpopup">
            <div className="join-gamename">
              {contest?.gameAry[0]?.name} - <span style={{ color: "rgba(219, 237, 241, 0.60)" }}>{contest?.gameTypeAry?.name}</span>
            </div>

            <div className="joing-pop-datetime">
              {formatDate2(contest?.date)}, {formatTime(contest?.time)}
            </div>
          </div>
        </div>
        <div className="superpower-team-confirmation">
          <div className="joinggamename-confirmation-pop">Confirmation </div>
          <div className="d-flex justify-content-center joinggamename-balance-pop">
            KCash Balance ={" "}
            <span className="icon" style={{ paddingLeft: "7px"}}>
              <img src={url.image_url + contest?.currency?.[0]?.inCurrency?.img?.default} alt="" />
            </span>{" "}
            {currentBalance}
          </div>
        </div>
        <div className="superpower-team-fee">
          <div className="joingpopup">
            <div className="joingamename-pop"> Entry Fee </div>
            <div className="joing-pop-datetime">
              {contest?.entryFee > 0 ? (
                <>
                  {" "}
                  <span className="icon">
                    <img src={url.image_url + contest?.currency?.[0]?.inCurrency?.img?.default} alt="" />
                  </span>{" "}
                  {contest?.entryFee}
                </>
              ) : (
                "Free"
              )}
            </div>
          </div>
          <div className="joingpopup">
            <div className="joingamename-pop"> Your Fee </div>
            <div className="joing-pop-datetime">
              {contest?.entryFee > 0 ? (
                <>
                  {" "}
                  <span className="icon">
                    <img src={url.image_url + contest?.currency?.[0]?.inCurrency?.img?.default} alt="" />
                  </span>{" "}
                  {contest?.entryFee}
                </>
              ) : (
                "Free"
              )}
            </div>
          </div>
        </div>
        <div className="superpower-team-btn mb-5" onClick={handlerJoin}>
          <button className="popup-button my-contest-button" disabled={isButtonDisable}>
            REGISTER
          </button>
        </div>
      </Popup>
    </>
  );
};

export default ContestConfirmationPopup;
