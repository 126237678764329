// Path: src\Component\screens\contestStructure.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import RightArrow from "../../assets/images/right-arrow.png"
import Header from "../../Component/Home/header";
import ShareIcon from "@mui/icons-material/Share";
import ShareIconn from "../../../src/assets/images/Vector.png"
import moment from "moment";
import url from "../../constants/url";
import ContestConfirmationPopup from "../Popup/ConstestConfirmatinoPopup";
import { useNavigate } from "react-router-dom";
import { routehelp } from "../config/routehelp";
import ToastPopup from "../Popup/ToastPopup";
import { getInGameNameByIdentifier } from "../../apis";
import WinningBreakup from "../Popup/WinningBreakup";

import { RWebShare } from "react-web-share";
import { formatDate, formatTime, shareLink } from "../Utilities/helper";
import { addTrackData, trackerEventId } from "../../config/tracker";

const ContestStructure = ({ handleRegisterClick, contest, uniqueIgn }) => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isPricePointPoolModel, setPricePointPool] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isIsTournamentConfirmationPopup, setIsTournamentConfirmationPopup] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const totalSlots = contest?.totalPlayers || 0;
  const slotsFilled = contest?.totalJoinedPlayers || 0;
  const percentageFilled = (slotsFilled / totalSlots) * 100;
  const remainingPlayers = contest?.totalPlayers - contest?.totalJoinedPlayers;
  const redFillWidth =
    contest?.totalJoinedPlayers >= contest?.minimumSlot
      ? 0
      : ((contest?.minimumSlot || 0) / totalSlots) * 100; // Width of the red fill

  const handleClick = (contest) => {
    if (contest?.isJoined) {
      navigate("/contest/details/" + contest?._id);
    }
    // if (handleRegisterClick) {
    //   handleRegisterClick(contest);
    // } else {
    //   setIsTournamentConfirmationPopup(true);
    // }
    if (!uniqueIgn?.trim()) {
      setErrorMsg("Please enter your in game user name first");
      setShowToast(true);
    } else {
      setIsTournamentConfirmationPopup(true);
    }
  };
  return (
    <>
      <Box className="newtournamentbox-withshare--wrap">
      <div className="newtournament-list-title-bar--withshare mt-3">
                    <div className="newtournament-list-title">

                    {contest?.title?.length > 18 ? <marquee>{contest?.title}</marquee> : contest?.title}

                    </div>

                    <div className="newtournamentbox-btn-list--wrap">
                        <div className="newtournamentbox-list-status-withshare"  onClick={() => {
                      navigator.clipboard.writeText(contest?.shortCode);
                    }}>
                        ID:{contest?.shortCode}
                        </div>
                        <div onClick={()=>{
                          addTrackData(trackerEventId?.Contest_Share_Button_Click, {
                            gameCatalogName: `${contest?.gameAry[0]?.name}`,
                            gameCatalogId: `${contest?.gameAry[0]?._id}`,
                            contestId: `${contest?._id}`,
                          });
                        }}>
                        <RWebShare
                data={shareLink(
                  localStorage?.getItem("game"),
                  contest?.gameTypeAry?._id,
                  contest?.gameTypeAry?.name,
                  contest?._id,
                  "contest"
                )}                            sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
                            onClick={() => {
                              console.log("shared successfully!");
                              
                            }}
                        >
                            <div

                                className="newtournament-share-button"


                            >

                                Share
                                <img className="newtournament-share-icon1" src={ShareIconn} alt=""></img>
                            </div>
                        </RWebShare>
                        </div>
                    </div>
                </div>

        <div className="row newcontest-basicdetails">
          <div className="newcontest--wrap">
            <div className="newcontest">
              <div className="newcontestbox-list-label">Date</div>
              <div className="newcontestbox-list-value">
                <span className="value-wrapper"> {formatDate(contest?.date)}</span>
              </div>
            </div>
            <div className="newcontest">
              <div className="newcontestbox-list-label">Time</div>
              <div className="newcontestbox-list-value">
                <span className="value-wrapper"> {formatTime(contest?.time)}</span>
              </div>
            </div>
            {contest?.titles?.[0]?.isSelection && contest?.titles?.[0]?.name && contest?.titles?.[0]?.value && (

              <div className="newcontest">
                <div className="newcontestbox-list-label">{contest?.titles?.[0]?.name}</div>
                <div className="newcontestbox-list-value truncate">
                  <span className="value-wrapper truncate-map-box">  {contest?.titles?.[0]?.value}</span>
                </div>
              </div>
            )}
            {contest?.titles?.[1]?.isSelection && contest?.titles?.[1]?.name && contest?.titles?.[1]?.value && (

              <div className="newcontest">
                <div className="newcontestbox-list-label">{contest?.titles?.[1]?.name}</div>
                <div className="newcontestbox-list-value">
                  <span className="value-wrapper truncate-map-box"> {contest?.titles?.[1]?.value}</span>
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-6">
            <div className="newcontestprize--wrap">
                <div className="newcontest">
                  <div className="newcontestbox-list-label">Prize Pool</div>
                  <div className="newcontestbox-list-value-icon">
                    <span>
                      <img className="newcontest-list-currency-icon1" 
                       src={
                        url?.image_url +
                        contest?.currency?.[0]?.outCurrency?.img?.default
                      } />                    </span>
                    <span className="newcontest-list-currency-amount value-wrapper">{contest?.prizePool}</span>
                  </div>
                </div>
                <div className="newcontest">
                  <div className="newcontestbox-list-label">{contest?.winningModel == "perKill" ? "Per Kill" : "Winners"}</div>
                  <div className="newcontestbox-list-value-icon-dropdown">
                  {contest?.winningModel === "perKill" ? (
                    <span className="newcontest-list-currency-amount value-wrapper">  
                    <img className="newcontest-list-currency-icon1" src={url?.image_url + contest?.currency?.[0]?.outCurrency?.img?.default} alt="" /></span>
                  ) : null}
                  <span className="newcontest-list-currency-amount value-wrapper">
                      {contest?.winningModel === "perKill" 
                        ? contest?.killPoints 
                        : contest?.totalWinners}
                    </span>
                    {contest?.winningModel != "perKill" ? (
                   <div onClick={() => setPricePointPool(true)}>
                      <img className="newcontest-list-currency-icon" src={RightArrow} />
                    </div>
                    ) : (
                      <></>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <div className="newcontest">
                <div className="newcontestbox-list-label">Join Using</div>
                <div className="newcontestbox-list-value-icon">
                  {contest?.entryFee > 0 ? (
                    <span>
                      <img className="newcontest-list-currency-icon1" src={url.image_url + contest?.currency?.[0]?.inCurrency?.img?.default} alt="" />
                    </span>
                  ) : (
                    "" )}
                  <span className="newcontest-list-currency-amount value-wrapper">  {contest?.entryFee > 0
                    ? contest?.entryFee
                    : "Free"}</span>
                </div>
              </div>
            </div>
            <Grid container spacing={2} className="details-grid slots-info">
              <Grid item xs={12} className="details-grid-box">
                <div className="progress-bar-wrapper mt-3 custome-progressbar">
                  <LinearProgress
                    variant="determinate"
                    value={percentageFilled || 0}
                    className="custom-linear-progress"
                  />
                  <Tooltip
                    title={`Minimum ${contest?.minimumSlot || 0} ${contest?.minimumSlot>1?'slots':'slot'} Required`}
                    arrow
                    placement="top"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "#1F3338",
                          border: "1px solid #87A7A6",
                          color: "#86A6A6", // Text color
                          fontSize: "0.75rem",
                          filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                          borderRadius: "4px",
                          padding: "8px",
                          width: "162px",
                          height: "50.758px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        "& .MuiTooltip-arrow": {
                          color: "#1F3338", // Arrow background color
                          "&::before": {
                            boxShadow: "0 0 0 1px #86A6A6", // Simulating white border
                          },
                        },
                      },
                    }}
                    classes={{ tooltip: "custom-tooltip" }}>
                    <div
                      className="progress-bar-red-fill"
                      style={{
                        width: `${redFillWidth || 0}%`,
                        left: `${percentageFilled || 0}%`,
                        position: "absolute",
                        height: "100%",
                      }}
                    />
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={6} className="details-grid-box mt-1">
                <div className="newcontestslots-remaining">{contest?.totalPlayers - contest?.totalJoinedPlayers} {(contest?.totalPlayers - contest?.totalJoinedPlayers)>1? 'slots':'slot'} remaining</div>
              </Grid>
              <Grid item xs={6} textAlign="right" className="details-grid-box mt-1">
                <div className="newcontestslots-filled">{contest?.totalJoinedPlayers} {contest?.totalJoinedPlayers>1? 'slots':'slot'} filled</div>
              </Grid>

            </Grid>
          </div>
        </div>
        <div className="newcontest-registerjoined-container">
          {contest?.isJoined == false && remainingPlayers > 0 ? (
            <div
              className="newcontest-regiter-container"
              onClick={() => handleClick(contest)}
            >
              <Grid container spacing={2} className="details-grid slots-info">
                <Grid
                  item
                  xs={6}
                  textAlign="center"
                  className="details-grid-box"
                >
                  <div className="newcontest-tournamentregbtn">REGISTER</div>
                </Grid>
              </Grid>
            </div>
          ) : contest?.isJoined === false && remainingPlayers === 0 ? (
            <div className="newcontest-regiter-container">
              <Grid container spacing={2} className="details-grid slots-info">
                <Grid
                  item
                  xs={6}
                  textAlign="center"
                  className="details-grid-box"
                >
                  <div className="newcontest-tournamentregbtn">FULL</div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              className="newcontest-regiter-container-joined"
              onClick={() => handleClick(contest)}
            >
              <Grid container  className="details-grid slots-info">
                <Grid
                  item
                  xs={12}
                  textAlign="center"
                  className="details-grid-box"
                >
                  <div className="newcontest-tournamentregbtnjoined">JOINED</div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>

      </Box>
      {isPricePointPoolModel && (
        <WinningBreakup
          isOpen={isPricePointPoolModel}
          title="Winning Breakup"
          onClose={() => setPricePointPool(false)}
          prizePool={contest}
          tournaments={contest}
        />
      )}
      {isIsTournamentConfirmationPopup && (
        <ContestConfirmationPopup
          isOpen={isIsTournamentConfirmationPopup}
          title={contest?.title}
          onClose={() => setIsTournamentConfirmationPopup(false)}
          contest={contest}
          uniqueIgn={uniqueIgn}
          setIsContestConfirmationPopup={setIsTournamentConfirmationPopup}
          setErrorMsg={setErrorMsg}
          setShowToast={setShowToast}
        />
      )}
      {showToast && (
        <ToastPopup message={errorMsg} setShowToast={setShowToast} />
      )}
    </>
  );
};

export default ContestStructure;
