import React, { useState, useEffect } from "react";
import Popup from "./basepopup";
import moment from "moment";
import { getInGameNameByIdentifier, tournamentMatchByRoundId } from "../../apis"
import { addTrackData, trackerEventId } from "../../config/tracker";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const TournamentRoundPopup = ({ isOpen, title, onClose, tournaments, rounds, firstRoundId, setFirstRoundId, uniqueIgn, setUniqueIgn, setIsTournamentRoundPopup, setIsTournamentConfirmationPopup, setSelectedMatchDate, setErrorMsg, setShowToast}) => {
    const [currentRound, setCurrentRound] = useState(0);    
    const [contestList, setContestList] = useState([]);
    const [finaleData, setFinaleData] = useState(null);
    const [firstRoundMatchId, setFirstRoundMatchId] = useState("");
    const [uniqueIgnData, setUniqueIgnData] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [roundsNext, setRounds] = useState([]);
    const [contest, setContest] = useState("");

    useEffect(() => {
        CheckRounds()
        addTrackData(trackerEventId?.Tournament_Popup_Shown, { gameCatalogName: `${localStorage.getItem("selectedGameName")}`, gameCatalogId: `${localStorage.getItem("game")}`, tournamentId: `${tournaments?.id}` });

    }, [rounds])

    const CheckRounds = async () => {
        var today = new Date();

        let result = rounds?.item?.filter((item) => {
            var itDate = moment(item?.date).format("y-MM-DD");
            var itTime = moment(item?.time).format("HH:mm:ss");

            console.log("uniqueItems",itDate + " " + itTime)
            
            var itmeD = moment(itDate + " " + itTime);
            if( today < itmeD) return true
        })

        const uniqueItems = [];
        const seenTimes = new Set();

        result?.forEach(obj => {
            var itDate = moment(obj?.date).format("y-MM-DD");
            var itTime = moment(obj?.time).format("HH:mm");
            var currentTime = itDate + " " + itTime

            if (!seenTimes.has(currentTime)) {
                uniqueItems.push(obj);
                seenTimes.add(currentTime); // Mark this time as seen
            }
        });

        console.log("uniqueItems",uniqueItems)
        setContestList(uniqueItems)
        setFinaleData(rounds?.lastRound);
        setFirstRoundMatchId("");
        var tempRound = rounds?.item?.[rounds?.item.length - 1];

        var today = new Date();
        var cdate = moment(tempRound?.date).format("y-MM-DD");
        var ctime = moment(tempRound?.time).format("HH:mm:ss");
        var contestDateTime = moment(cdate + " " + ctime);

        var isShowTournament = true;
        if (result.length == 0 || today > new Date(contestDateTime)) {
            isShowTournament = false;
        }
        isShowTournament = true;

        if (isShowTournament) {
            var uniqueI = await InGameNameApiCall() || "";
            if (!uniqueI?.trim()) {
                setErrorMsg("Please enter your in game user name first");
                setIsTournamentRoundPopup(false);
                setIsTournamentConfirmationPopup(false);
                setShowToast(true)
            }
            else 
            {
                setUniqueIgnData(uniqueI);
                setUniqueIgn(uniqueI);   
            }
        }
        else {
            setErrorMsg("Please update user name in your account.");
            setIsTournamentRoundPopup(false);
            setIsTournamentConfirmationPopup(false);
            setShowToast(true)
        }
    }

    const InGameNameApiCall = async () => {
        let existsUserInfo = JSON?.parse(localStorage?.getItem('profile'));

        let payload = {
            "game": localStorage?.getItem("game")
        }

        if (existsUserInfo?.phoneNumber) {
            payload.user_name = existsUserInfo?.phoneNumber;
            payload.countryCode = existsUserInfo?.countryCode;
            payload.type = "phoneNo"
        }
        else {
            payload.user_name = existsUserInfo?.email;
            payload.type = "email"
        }

        let gameNamesData = (await getInGameNameByIdentifier(payload))
        if (gameNamesData?.success) {
            return gameNamesData?.data?.username || ""
        }
        else {
            return ""
        }
    }

    const handleRadioChange = async (item) => {
        let body = {
            code: item?.code
        }

        let response = await tournamentMatchByRoundId(tournaments?._id, body);
        if (response?.success) {
            setFirstRoundMatchId(item._id);
            setSelectedDate(moment(item?.date).format("D MMMM YYYY")+", "+moment(item?.time).format("hh:mm A"));
            setSelectedMatchDate(moment(item?.date).format("D MMM YYYY")+", "+moment(item?.time).format("hh:mm A"));
            let temp = [];
            temp = response?.item?.reduce((item, val1)=>{
                let data = {
                round: val1.name,
                date: val1?.matches?.[0]?.date,
                time:val1?.matches?.[0]?.time
        
                }
                item.push(data)
                return item
            },[])
            setRounds(temp);
            setContest(item._id);
        }
    };
    
    const handlePreviousRound = () => {
        if (currentRound > 0) {
            setCurrentRound(currentRound - 1);
        }
    };
    
    const handleNextRound = () => {
        if (currentRound < (roundsNext?.length - 1)) {
            setCurrentRound(currentRound + 1);
        }
    };

    const onNext = () => {
        if (firstRoundMatchId !== "") {
            setFirstRoundId(firstRoundMatchId)
            setIsTournamentRoundPopup(false)
            setIsTournamentConfirmationPopup(true)
        } else {
            setErrorMsg("Please select match.");
            setIsTournamentRoundPopup(false);
            setIsTournamentConfirmationPopup(false);
            setShowToast(true)
        }
    };
    
    return (
        <Popup isOpen={isOpen} title={title} onClose={onClose}>
            <>
                <div className="tournament-details">
                    <p className="tournamentdetails-name">{tournaments?.gameAry?.name} -{" "} {tournaments?.gameTypeAry?.name}</p>
                    <p className="tournamentdetails-date">
                    {moment(tournaments?.startDate).format("DD MMMM Y")} to {moment(tournaments?.endDate).format("DD MMMM Y")}
                    </p>

                    <div className="tournamentdetails-selectround">
                        Select {currentRound + 1}st Round Timing
                    </div>

                    <div className="timing-options">
                        {contestList.map((item, index) => (
                            <label
                                className="radio-option"
                            >
                            <input
                                type="radio"
                                name="round-timing"
                                onChange={() =>
                                    handleRadioChange(item)
                                }
                            />
                            <span
                                className="tournamentdatetime"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <span>{moment(item?.date).format("D MMMM YYYY")} </span>
                                <span>{moment(item?.time).format("hh:mm A")}</span>
                            </span>
                            </label>
                        ))}
                    </div>

                    {firstRoundMatchId === "" ? (
                        <p className="tournamentinfoline">
                            Select match to check next round timing
                        </p>
                    ) : (
                        <>
                            <div className="round-navigation">
                                <button
                                    onClick={handlePreviousRound}
                                    disabled={currentRound === 0}
                                    style={{
                                    opacity: currentRound === 0 ? 0.5 : 1,
                                    background: "none",
                                    border: "none",
                                    cursor: currentRound === 0 ? "default" : "pointer",
                                    }}
                                >
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    >
                                    <path
                                        d="M0.25 5L5.06336 0L6.5 0V1.69357L3.31391 5L6.5 8.30643V9.99751L5.06336 10L0.25 5Z"
                                        fill={currentRound === 0 ? "#A9A9A9" : "#8CFF05"}
                                    />
                                    </svg>
                                </button>
                                    <span>{ roundsNext?.[currentRound]?.round }</span>

                                <button
                                    onClick={handleNextRound}
                                    disabled={currentRound === (roundsNext?.length - 1)}
                                    style={{
                                    opacity: currentRound === (roundsNext?.length - 1) ? 0.5 : 1,
                                    background: "none",
                                    border: "none",
                                    cursor:
                                        currentRound === (roundsNext?.length - 1) ? "default" : "pointer",
                                    }}
                                >
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    >
                                    <path
                                        d="M6.75 5L1.93664 0L0.500001 0V1.69357L3.68609 5L0.500001 8.30643V9.99751L1.93664 10L6.75 5Z"
                                        fill={
                                        currentRound === (roundsNext?.length - 1) ? "#A9A9A9" : "#8CFF05"
                                        }
                                    />
                                    </svg>
                                </button>
                            </div>
                            <p className="tournamentselecteddatetime">
                                {moment(roundsNext?.[currentRound]?.date).format("D MMMM YYYY")} {moment(roundsNext?.[currentRound]?.time).format("hh:mm A")}
                            </p>
                            <p className="tournamentinfoline">
                                FINAL will be scheduled at {moment(finaleData?.date).format("D MMMM YYYY")} {moment(finaleData?.time).format("hh:mm A")}
                            </p>
                        </>
                    )}

                    <button className="popup-button my-contest-button mb-5" onClick={() => onNext()} disabled={firstRoundMatchId !== "" ? false : true}> 
                        SUBMIT
                    </button>
                </div>    
            </>
        </Popup>
    );
};

export default TournamentRoundPopup;