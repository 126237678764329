import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Button,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import Header from "../../Component/Home/header";
import Tournaments from "../../../src/assets/images/Tournaments.png";
import Tooltip from "@mui/material/Tooltip";
import Popup from "../Popup/basepopup";
import Roundimg from "../../../src/assets/images/table1.png";
import { ReactComponent as Iconsvg } from "../../../src/assets/images/icon.svg";
import { ReactComponent as Circlesvg } from "../../../src/assets/images/circle.svg";
import QualificationPopup from "../Popup/QualificationPopup";
import WinningBreakup from "../Popup/WinningBreakup";
import { useParams } from "react-router-dom";
import url from "../../constants/url";
import moment from "moment";
import { useNavigate } from "react-router";
import { ReactComponent as Clipcopy } from "../../../src/assets/images/clipcopy.svg";
import RightArrow from "../../../src/assets/images/right-arrow.png"
import { tournamentById, tournamentPricePointPoolApi, indiggTournamentRoundMatch, indiggTournamentDetailPlayers, tournamentRulesApi } from "../../apis";

import Loader from "../loader";

import { RWebShare } from "react-web-share";
import TournamentPlayers from "./tournament-details/tournament-players";
import { formatDate, formatTime, shareLink } from "../Utilities/helper";
import { addTrackData, trackerEventId } from "../../config/tracker";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box className="tab-content">{children}</Box>}
    </div>
  );
};

const TournamentDetails = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isRoundPopupOpen, setIsRoundPopupOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const totalSlots = 80;
  const slotsFilled = 8;
  const percentageFilled = (slotsFilled / totalSlots) * 100;
  const redFillWidth = 10; // Width of the red fill
  const [activeTab, setActiveTab] = useState("Prize Pool");
  const [isInputVisible, setInputVisible] = useState(false);
  const [isOpen, setIsOpen] = useState([true]);
  const [isRound, setIsRound] = useState(false);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  let { id } = useParams();
  const [tournaments, setTournamentDetails] = useState({});
  const [prizePool, setPrizePool] = useState('');
  const [contests, setContests] = useState([]);
  const [captainData, setCaptainData] = useState(null);
  const [playerCaptainData, setPlayerCaptainData] = useState({});
  const [allPlayersData, setAllPlayersData] = useState([]);
  const [playerCount, setPlayerCount] = useState(0);
  const [rules, setRules] = useState('');
  const navigate = useNavigate();
  const [selectedContestData, setSelectedContestData] = useState({});
  const [isLoading, setIsLoader] = useState(false);
  const [qualifiedForLastRound, setQualifiedForLastRound] = useState(false);

  useEffect(() => {
    fetchData();
    getTournamentMatches();
    addTrackData(trackerEventId?.Tournament_Details_Shown, { gameCatalogName: `${localStorage.getItem("selectedGameName")}`, gameCatalogId: `${localStorage.getItem("game")}`, tournamentId: `${id}` });
  }, [id])

  const fetchData = async () => {
    setIsLoader(true);
    try {
      let response = await tournamentById(id);
      if (response?.success) {
        setIsLoader(false);
        setTournamentDetails(response?.item);
      }
      else {
        setIsLoader(false);
        setTournamentDetails({});
      }
    } catch (error) {
      setIsLoader(false);
      setTournamentDetails({});
      console.log("getTournament error");
    }
  }

  const getTournamentMatches = async () => {
    setIsLoader(true);
    try {
      let data = {
        user: JSON?.parse(localStorage.getItem('profile'))?.user_id,
        event: id,
      };
      const res = await indiggTournamentRoundMatch(data, id);
      if (res?.contests) {
        setIsLoader(false);
        setContests(res?.contests)
        let round = [];
        res?.contests?.map((item, index) => {
          if (item?.contest?.isFinalRound) {
            setQualifiedForLastRound(true)
          }
          if ((res?.contests?.length - 1) === index) {
            round.push(0);
          }
          else {
            round.push(1);
          }
        });
        setIsOpen(round);
        setCaptainData(res?.contests?.[0])
      }
      else {
        setIsLoader(false);
      }
    } catch (e) {
      setIsLoader(false);
    }
  }

  const fetchPlayer = async () => {
    setIsLoader(true);
    try {
      let payload = {
        skip: 0,
        limit: 10,
        event: id,
        user: JSON?.parse(localStorage?.getItem('profile'))?.user_id,
        sortBy: "createdAt",
        sort: "desc",
      };

      const res = await indiggTournamentDetailPlayers(payload, id);
      if (res?.success) {
        setIsLoader(false);
        setPlayerCaptainData(res?.captainData)
        setAllPlayersData(res?.list)
        setPlayerCount(res?.count)
      }
      else {
        setIsLoader(false);
      }
    } catch (e) {
      setIsLoader(false);
    }
  }

  async function fetchRulesData() {
    setIsLoader(true);
    try {
      let response = await tournamentRulesApi(tournaments?._id);
      if (response?.success) {
        setIsLoader(false);
        setRules(response?.item?.rules);
      }
      else {
        setIsLoader(false);
        setRules('');
      }
    } catch (error) {
      setIsLoader(false);
      setRules('');
      console.log("getTournament error");
    }
  }

  // Function to toggle the visibility
  const toggleInputVisibility = () => {
    setInputVisible((prevVisible) => !prevVisible);
  };

  const toggleDetailsVisibility = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleBackClick = () => {
    navigate(-1)
  };

  const handleDivClick = (number) => {
    // let temp = [...isOpen];
    // temp[number] = temp[number] === 1 ? 0 : 1;
    // setIsOpen(temp);

    let round = [];
    contests?.map((item, index) => {
      if (number === index) {
        let temp = [...isOpen];
        let status = temp[number] === 1 ? 0 : 1;
        round.push(status);
      }
      else {
        round.push(1);
      }
    });
    setIsOpen(round);

  };
  const handleRoundClick = () => {
    setIsRound(!isRound); // toggle state
  };

  const capitalize = (str) => {
    if (!str) return ''; // Handle empty or null strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  async function fetchPricePointPoolData() {
    setIsLoader(true);
    try {
      let response = await tournamentPricePointPoolApi(tournaments?._id);
      if (response?.success) {
        setIsLoader(false);
        setPrizePool(response?.data);
      }
      else {
        setIsLoader(false);
        setPrizePool('');
      }
      setIsPopupOpen(true)
    } catch (error) {
      setIsLoader(false);
      setPrizePool('');
      console.log("getTournament error");
    }
  }

  let getRoundStatus = (contest, ind) => {
    if (contest.isFinalRound) {
      return undefined;
    }
    if (contest.status == "waiting") {
      return "Pending";
    }
    if (contest.status != "completed") {
      return "Pending";
    }
    if (contests.length > 0) {
      return "Qualified";
    }
    return "Not Qualified";
  };

  let getRoundStatusDesc = (contest, ind) => {
    if (contest.isFinalRound) {
      return undefined;
    }
    if (getRoundStatus(contest, ind) == "Pending") {
      return "Pending";
    }
    if (getRoundStatus(contest, ind) == "Qualified") {
      return "Qualified For Next Round";
    }
    return "Not Qualified For Next Round";
  };

  const findPercentage = (data) => {
    let totalPlayer = data?.totalPlayers || 0;
    let totalPlayerJoined = data?.totalJoinedPlayers || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage;
  };

  const findPercentageRedFlag = (data) => {
    let totalPlayer = data?.totalPlayers || 0;
    let totalPlayerJoined = data?.minimumSlot || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  const getQulified = (item) => {
    setIsRoundPopupOpen(true);
    setSelectedContestData(item);
  };

  return (


    <Header title="Tournament Details" onBackClick={handleBackClick}>
      {isLoading ? <Loader /> : <></>}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        className="tabs-container player-details-tabs player-details-rules-tabs"
        centered
      >
        <Tab label="Details" className="tab" />
        <Tab label="Table" className="tab" />
        <Tab label="Players" className="tab" />
        <Tab label="Rules" className="tab" onClick={() => fetchRulesData()} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <div className="tournamentdetails-scroll tournament-rules-details-scroll">
          <div className="newcontestbox-list mb-2">
            <div className="newcontest-list-title-bar mt-3">
              <div className="newcontestbox-list-title">

                <div className="">{tournaments?.title?.length > 30 ? <marquee>{tournaments?.title}</marquee> : tournaments?.title}</div> </div>

              <div className="newcontestbox-list-status">
                {capitalize(tournaments?.status)}
              </div>
            </div>

            {/* basic details */}
            <div className="row newcontest-basicdetails pb-3">
              <div className="row newtournament-basicdetails">
                <div className="col-4 newtournament-image-wrapper">
                  <div className="newtournament-image-container">
                    {tournaments?.featuredImage?.default ? (
                      <img src={url.image_url + tournaments?.featuredImage?.default} alt="" className="newtournament-image" />
                    ) : (
                      <img src={Tournaments} alt="" className="newtournament-image" />
                    )}
                    <span className="newtournament-image-image-text">{tournaments?.gameTypeAry?.name}</span>
                  </div>
                </div>
                <div className="col-8 newtournament-details--wrap ps-2">

                  <div className="newcontest">
                    <div className="newcontestbox-list-label">Date</div>
                    <div className="newcontestbox-list-value">
                      <span className="value-wrapper"> {formatDate(tournaments?.startDate)}</span>
                    </div>
                  </div>
                  <div className="newcontest">
                    <div className="newcontestbox-list-label">Rounds</div>
                    <div className="newcontestbox-list-value">
                      <span className="value-wrapper">{tournaments?.rounds}</span>
                    </div>
                  </div>
                  <div className="newcontest">
                    <div className="newcontestbox-list-label">ID</div>
                    <div className="newcontestbox-list-value">
                      <span className="value-wrapper"
                        onClick={() => {
                          navigator.clipboard.writeText(tournaments?.shortCode);
                        }}>{tournaments?.shortCode}</span>
                    </div>
                  </div>
                  <div className="newtournament--wrap">
                    {tournaments?.titles?.[0]?.isSelection && tournaments?.titles?.[0]?.name && tournaments?.titles?.[0]?.value &&
                      <div className="newcontest">
                        <div className="newcontestbox-list-label">{tournaments?.titles?.[0]?.name}</div>
                        <div className="newcontestbox-list-value">
                          <span
                            className="value-wrapper"
                            style={{
                              display: 'inline-block',
                              maxWidth: '100%', // limits to 5 characters width
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {tournaments?.titles?.[0]?.value}
                          </span>

                        </div>


                      </div>

                    }
                    {tournaments?.titles?.[1]?.isSelection && tournaments?.titles?.[1]?.name && tournaments?.titles?.[1]?.value &&
                      <div className="newcontest">
                        <div className="newcontestbox-list-label">{tournaments?.titles?.[1]?.name}</div>
                        <div className="newcontestbox-list-value">
                          <span className="value-wrapper">{tournaments?.titles?.[1]?.value}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="newtournament-prizedetails">
                <div className="newcontest-prize--wrap">
                  <div className="newcontest">

                    <div className="newcontestbox-list-label">Prize Pool</div>
                    <div className="newcontestbox-list-value-icon">
                      {
                        tournaments?.prizePoolType === 'amount' &&
                        <span>
                          <img className="newcontest-list-currency-icon1" src={
                            url?.image_url +
                            tournaments?.currency?.[0]?.outCurrency?.img?.default
                          }
                            alt="" />
                        </span>
                      }
                      <span className="newcontest-list-currency-amount value-wrapper">  {tournaments?.prizePoolType === 'amount' ? tournaments?.prizePool : tournaments?.rewardDisplayText}</span>
                    </div>
                  </div>
                  <div className="newcontest">
                    <div className="newcontestbox-list-label">Winners</div>
                    <div className="newcontestbox-list-value-icon-dropdown">
                      <div className="newcontest-list-currency-amount value-wrapper">
                        {tournaments?.totalWinners}
                      </div>
                      <span className="newcontest-list-currency-amount value-wrapper" onClick={() => fetchPricePointPoolData()}>
                        <img className="newcontest-list-currency-icon1" src={RightArrow} alt="" style={{ marginRight: '3px' }} />

                      </span>


                    </div>
                  </div>
                </div>
                <div className="newcontest">
                  <div className="newcontestbox-list-label">Join Using</div>
                  <div className="newcontestbox-list-value-icon">
                    <span className="newcontest-list-currency-amount value-wrapper">
                      {tournaments?.entryFee > 0 ?
                        <img className="newcontest-list-currency-icon1" src={
                          url.image_url +
                          tournaments?.currency?.[0]?.inCurrency?.img?.default
                        } alt="" /> : <></>} {tournaments?.entryFee > 0 ? tournaments?.entryFee : "Free"}
                    </span>

                  </div>
                </div>
              </div>

            </div>

          </div>

          {contests.length > 0 && (
            <div className="tournaments-rounds-details">
              <h3 className="tournaments-rounds-heading">Tournament Rounds</h3>
              {contests.map((data, indp) => (
                <>
                  <Box
                    className={`tab-panel-box ${getRoundStatus(data?.contest, indp) === "Not Qualified" ? "not-qualified" : ""} ${isOpen[indp] === 0 ? "tab-panel-box-open" : ""}`}
                  >
                    {/* Superpower Section */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="superpower-container"
                    >
                      <div
                        className="superpower-title col-4"
                        style={{ paddingLeft: "1rem" }}
                      >
                        {data?.contest?.title}
                      </div>
                      {data?.contest?.isFinalRound ?
                        <div className="row">
                          <div
                            className={`col-6 superpower-box superpower-box-id alignitemcenter ${data?.contest?.isFinalRound && "share"}`}
                             onClick={()=>{
                              addTrackData(trackerEventId?.Tournament_Share_Button_Click, { gameCatalogName: `${tournaments?.gameAry?.name}`, gameCatalogId: `${tournaments?.gameAry?._id}`, tournamentId: `${tournaments?._id}` });
                             }}
                          >
                            <RWebShare
                              data={shareLink(localStorage?.getItem('game'), null, null, tournaments?._id, 'tournament')}
                              // data={{
                              // text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
                              // url:
                              //     `${url?.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem('game')}&type=tournament`
                              // }}
                              sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
                              onClick={() => {
                                console.log("shared successfully!")
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                className="completed-button completed-share-button"
                                disableElevation
                                style={{ width: "6rem" }}
                                endIcon={
                                  <svg
                                    className="share-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 14"
                                    fill="none"
                                  >
                                    <path d="M15.7601 6.72045L9.98285 0.943246C9.92226 0.882726 9.84508 0.84152 9.76108 0.824833C9.67708 0.808146 9.59002 0.816726 9.5109 0.849489C9.43177 0.882253 9.36413 0.93773 9.31651 1.00891C9.2689 1.0801 9.24344 1.1638 9.24337 1.24944V4.29691C5.3091 4.55616 0.549405 8.26873 0.00490352 13.0104C-0.0127803 13.1614 0.0176339 13.3141 0.0918134 13.4468C0.165993 13.5795 0.280154 13.6854 0.418034 13.7494C0.555914 13.8134 0.710481 13.8323 0.859714 13.8033C1.00895 13.7743 1.14524 13.699 1.24917 13.5881C2.06231 12.7208 4.98268 9.95857 9.24337 9.7802V12.8038C9.24344 12.8895 9.2689 12.9732 9.31651 13.0444C9.36413 13.1155 9.43177 13.171 9.5109 13.2038C9.59002 13.2365 9.67708 13.2451 9.76108 13.2284C9.84508 13.2118 9.92226 13.1705 9.98285 13.11L12.8715 10.2214L15.7601 7.33283C15.8412 7.25159 15.8868 7.14146 15.8868 7.02664C15.8868 6.91182 15.8412 6.80169 15.7601 6.72045ZM10.1099 11.7582V9.33752C10.1099 9.2226 10.0643 9.11239 9.98304 9.03114C9.90178 8.94988 9.79158 8.90423 9.67666 8.90423C7.67414 8.90423 5.72217 9.42779 3.87635 10.4605C2.80051 11.0648 1.80952 11.8091 0.929256 12.6738C1.26867 10.7558 2.38511 8.91867 4.10672 7.45487C5.81099 6.01129 7.89223 5.14905 9.67666 5.14905C9.79158 5.14905 9.90178 5.1034 9.98304 5.02214C10.0643 4.94088 10.1099 4.83067 10.1099 4.71576V2.29583L14.8408 7.02664L10.1099 11.7582Z" />
                                  </svg>
                                }
                              >
                                <span>
                                  Share
                                </span>
                              </Button>
                            </RWebShare>
                          </div>
                          <div
                            className={`superpower-box col-2 ${data?.contest?.isFinalRound && "share share-toggle bg-transparent"} ${getRoundStatus(data?.contest, indp) === "Not Qualified" ? "bg-gray" : "bg-lime-gray"}`}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              className="completed-button completed-share-button"
                              disableElevation
                              onClick={() => handleDivClick(indp)} // toggle on click
                              endIcon={
                                <span>
                                  {isOpen[indp] === 0 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="18"
                                      viewBox="0 0 12 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M11.25 9L2.58595 18L2.69415e-06 18L2.5609e-06 14.9516L5.73496 9L2.0406e-06 3.04843L1.90754e-06 0.00447468L2.58595 3.78718e-07L11.25 9Z"
                                        fill="none"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="12"
                                      viewBox="0 0 18 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M9 11.25L-3.78718e-07 2.58595L-4.91753e-07 2.69415e-06L3.04843 2.5609e-06L9 5.73496L14.9516 2.0406e-06L17.9955 1.90754e-06L18 2.58595L9 11.25Z"
                                        fill="none"
                                      />
                                    </svg>
                                  )}
                                </span>
                              }
                            >
                            </Button>
                          </div>
                        </div>
                        :
                        <div
                          className={`superpower-box row col-8 ${getRoundStatus(data?.contest, indp) === "Not Qualified" ? "bg-gray" : "bg-lime-gray"}`} style={{ width: "68%" }}
                        >
                          <span className={`col-8 ${getRoundStatus(data?.contest, indp) === "Not Qualified" ? "" : "color-gray"}`}>
                            {getRoundStatusDesc(data?.contest, indp)}
                          </span>
                          <Button
                            variant="contained"
                            size="small"
                            className="completed-button completed-share-button col-2"
                            disableElevation
                            onClick={() => handleDivClick(indp)} // toggle on click
                            endIcon={
                              <span>
                                {isOpen[indp] === 0 ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="18"
                                    viewBox="0 0 12 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M11.25 9L2.58595 18L2.69415e-06 18L2.5609e-06 14.9516L5.73496 9L2.0406e-06 3.04843L1.90754e-06 0.00447468L2.58595 3.78718e-07L11.25 9Z"
                                      fill="none"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="12"
                                    viewBox="0 0 18 12"
                                    fill="none"
                                  >
                                    <path
                                      d="M9 11.25L-3.78718e-07 2.58595L-4.91753e-07 2.69415e-06L3.04843 2.5609e-06L9 5.73496L14.9516 2.0406e-06L17.9955 1.90754e-06L18 2.58595L9 11.25Z"
                                      fill="none"
                                    />
                                  </svg>
                                )}
                              </span>
                            }

                          >
                          </Button>
                        </div>
                      }
                    </div>

                    {/* add comment */}
                    {/* Details Grid */}
                    {isOpen[indp] === 0 && (
                      <>
                        <div className="details-container">
                          <div className="newcontest--wrap">
                            <div className="newcontest">
                              <div className="newcontestbox-list-label">Date</div>
                              <div className="newcontestbox-list-value">
                                <span className="value-wrapper"> {formatDate(data?.contest?.date)}</span>
                              </div>
                            </div>
                            <div className="newcontest">
                              <div className="newcontestbox-list-label">Time</div>
                              <div className="newcontestbox-list-value">
                                <span className="value-wrapper"> {formatTime(data?.contest?.time)}</span>
                              </div>
                            </div>
                            {!data?.contest?.isFinalRound ? (
                              <>
                                <div className="newcontest">
                                  <div className="newcontestbox-list-label">Qualified</div>
                                  <div className="newcontestbox-list-value-icon-dropdown">

                                    <span className="newcontest-list-currency-amount value-wrapper"> </span>
                                    {data?.contest?.winnerCount}
                                    <div onClick={() => getQulified(data?.contest)}>
                                      <img className="newcontest-list-currency-icon" src={RightArrow} />
                                    </div>

                                  </div>
                                </div>
                              </>

                            ) : (
                              <>
                                <div className="newcontest">
                                  <div className="newcontestbox-list-label">Winners</div>
                                  <div className="newcontestbox-list-value-icon-dropdown">

                                    <span className="newcontest-list-currency-amount value-wrapper"> </span>
                                    {tournaments?.totalWinners}
                                    <div onClick={() => fetchPricePointPoolData()}>
                                      <img className="newcontest-list-currency-icon" src={RightArrow} />
                                    </div>

                                  </div>
                                </div>
                              </>

                            )}



                            <div className="newcontest">
                              <div className="newcontestbox-list-label">ID</div>
                              <div className="newcontestbox-list-value-icon-dropdown">

                                <span className="newcontest-list-currency-amount value-wrapper">
                                  <div onClick={() => {
                                    navigator.clipboard.writeText(tournaments?.shortCode);
                                  }}>
                                    {tournaments?.shortCode}
                                  </div>
                                </span>

                              </div>
                            </div>
                          </div>

                          <Grid container spacing={2} className="details-grid">
                            <Grid item xs={12} className="details-grid-box">
                              <div className="progress-bar-wrapper mt-2 custome-progressbar">
                                <LinearProgress
                                  variant="determinate"
                                  value={findPercentage(data?.contest)}
                                  className="custom-linear-progress"
                                />
                                <Tooltip
                                  title={`Minimum ${data?.minimumSlot} ${data?.minimumSlot > 1 ? 'slots' : 'slot'} required`}
                                  arrow
                                  placement="top"
                                >
                                  <div
                                    className="progress-bar-red-fill"
                                    style={{
                                      width: `${0}%`,
                                      left: `${findPercentage(data?.contest)}%`,
                                      position: "absolute",
                                      height: "100%",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </Grid>
                            <Grid item xs={6} className="details-grid-box mt-2">
                              <div className="slots-remaining">{data?.contest?.totalPlayers - data?.contest?.totalJoinedPlayers} {(data?.contest?.totalPlayers - data?.contest?.totalJoinedPlayers) > 1 ? 'slots' : 'slot'} remaining</div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              textAlign="right"
                              className="details-grid-box mt-2"
                            >
                              <div className="slots-filled">{data?.contest?.totalJoinedPlayers} {data?.contest?.totalJoinedPlayers > 1 ? 'slots' : 'slot'} filled</div>
                            </Grid>
                            <div className="roomidpass-container">
                              <Grid container spacing={2} className="details-grid slots-info">
                                <Grid
                                  item
                                  xs={6}
                                  textAlign="center"
                                  className="details-grid-box"
                                >
                                  <div className="room-id-label details-label">Room ID</div>

                                  <div className="room-id-value">
                                    {data?.contest?.roomId === "" ? "-" : data?.contest?.roomId?.length > 15 ? (
                                      <marquee>{data?.contest?.roomId}</marquee>
                                    ) : (
                                      data?.contest?.roomId
                                    )}{" "}
                                    {data?.contest?.roomId != "" && (
                                      <span onClick={() => {
                                        navigator.clipboard.writeText(
                                          data?.contest?.roomId
                                        );
                                      }}>
                                        <Clipcopy />
                                      </span>
                                    )}
                                  </div>

                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  textAlign="center"
                                  className="details-grid-box details-grid-box-1"
                                >
                                  <div className="password-label details-label">Password</div>

                                  <div className="room-id-value">
                                    {data?.contest?.roomPassword === ""
                                      ? "-"
                                      : data?.contest?.roomPassword?.length > 15 ? (
                                        <marquee>{data?.contest?.roomPassword}</marquee>
                                      ) : (
                                        data?.contest?.roomPassword
                                      )}{" "}
                                    {data?.contest?.roomPassword != "" && (
                                      <span onClick={() => {
                                        navigator.clipboard.writeText(
                                          data?.contest?.roomPassword
                                        );
                                      }}>
                                        <Clipcopy />
                                      </span>
                                    )}
                                  </div>

                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </div>
                      </>
                    )}

                    {/* roomid pass */}
                  </Box>

                  {isOpen[indp] === 0 && (
                    <div className="user-tournamentdetails mt-5">
                      <Box className="tab-panel-box">
                        {/* Superpower Section */}
                        <div className="superpower-container borderradius">
                          <table
                            className="superpower-wapper"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            <thead style={{ background: "#8CFF05" }}>
                              <tr>
                                <th
                                  className="tableheading"
                                  style={{
                                    color: "white",
                                    padding: "16px",
                                    textAlign: "left",
                                  }}
                                >
                                  Username
                                </th>

                                <th
                                  className="tableheading"
                                  style={{
                                    color: "white",
                                    padding: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  Kills
                                </th>
                                <th
                                  className="tableheading"
                                  style={{
                                    color: "white",
                                    padding: "16px",
                                    textAlign: "left",
                                  }}
                                >
                                  Rank
                                </th>
                                <th
                                  className="tableheading"
                                  style={{
                                    color: "white",
                                    padding: "16px",
                                    textAlign: "left",
                                  }}
                                >
                                  Points
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                style={{

                                  backgroundColor: "transparent",
                                }}
                              >
                                <td
                                  className="tablevalues"
                                  style={{ padding: "13.5px 16px", textAlign: "left" }}
                                >
                                  {data?.user?.gamerjiName}
                                </td>

                                <td
                                  className="tablevalues"
                                  style={{
                                    padding: "13.5px 16px",
                                    textAlign: "center",
                                  }}
                                >
                                  {data?.kills || "-"}
                                </td>
                                <td
                                  className="tablevalues"
                                  style={{ padding: "13.5px 16px", textAlign: "left" }}
                                >
                                  {data?.rank || "-"}
                                </td>
                                <td
                                  className="tablevalues"
                                  style={{ padding: "13.5px 16px", textAlign: "left" }}
                                >
                                  {data?.totalPoint || "-"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Box>
                    </div>
                  )}
                </>
              ))}
            </div>
          )}

          {!qualifiedForLastRound &&
          <div  onClick={()=>{
            addTrackData(trackerEventId?.Tournament_Share_Button_Click, { gameCatalogName: `${tournaments?.gameAry?.name}`, gameCatalogId: `${tournaments?.gameAry?._id}`, tournamentId: `${tournaments?._id}` });
           }}>
            <RWebShare
              data={shareLink(localStorage?.getItem('game'), null, null, tournaments?._id, 'tournament')}
              // data={{
              //   text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
              //   url:
              //     `${url?.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem('game')}&type=tournament`
              // }}
              sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
              onClick={() => {
                console.log("shared successfully!")
              }}
            >
              <button className="sharewithfrnds">
                <div className="sharewithfrnds-heading">Share With Friends</div>
                <div className="sharewithfrnds-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path
                      d="M17.4564 7.35511L11.2064 1.10511C11.1408 1.03963 11.0574 0.995055 10.9665 0.977002C10.8756 0.958949 10.7814 0.968232 10.6958 1.00368C10.6102 1.03912 10.537 1.09914 10.4855 1.17615C10.434 1.25316 10.4065 1.34371 10.4064 1.43636V4.73323C6.15014 5.0137 1.00092 9.0301 0.41186 14.1598C0.392729 14.3231 0.425632 14.4883 0.505882 14.6319C0.586133 14.7754 0.709637 14.89 0.858801 14.9593C1.00796 15.0285 1.17518 15.0489 1.33663 15.0176C1.49807 14.9863 1.64552 14.9048 1.75795 14.7848C2.63764 13.8465 5.79702 10.8582 10.4064 10.6653V13.9364C10.4065 14.029 10.434 14.1196 10.4855 14.1966C10.537 14.2736 10.6102 14.3336 10.6958 14.369C10.7814 14.4045 10.8756 14.4138 10.9665 14.3957C11.0574 14.3777 11.1408 14.3331 11.2064 14.2676L17.4564 8.01761C17.5442 7.92971 17.5935 7.81058 17.5935 7.68636C17.5935 7.56214 17.5442 7.443 17.4564 7.35511ZM11.3439 12.8051V10.1864C11.3439 10.062 11.2945 9.94281 11.2066 9.8549C11.1187 9.76699 10.9995 9.71761 10.8751 9.71761C8.70874 9.71761 6.59702 10.284 4.60014 11.4012C3.43626 12.055 2.36416 12.8602 1.41186 13.7957C1.77905 11.7207 2.98686 9.73323 4.84936 8.14964C6.69311 6.58792 8.94467 5.65511 10.8751 5.65511C10.9995 5.65511 11.1187 5.60572 11.2066 5.51781C11.2945 5.4299 11.3439 5.31068 11.3439 5.18636V2.56839L16.4619 7.68636L11.3439 12.8051Z"
                      fill="#1F3338"
                    />
                  </svg>
                </div>
              </button>
            </RWebShare></div>
          }

          {isPopupOpen &&
            <WinningBreakup
              isOpen={isPopupOpen}
              title="Winning Breakup"
              onClose={() => setIsPopupOpen(false)}
              prizePool={prizePool}
              tournaments={tournaments}
            />
          }

          {isRoundPopupOpen && (
            <QualificationPopup
              isOpen={isRoundPopupOpen}
              onClose={() => setIsRoundPopupOpen(false)}
              selectedContestData={selectedContestData}
            />
          )}
        </div>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Box className="tab-panel-box">
          <img src={url?.image_url + tournaments?.eventTableImage?.default} alt="Round" />
        </Box>
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <TournamentPlayers tab={selectedTab} tournamentId={id} tournaments={tournaments} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <div className="contestdetailscroll">
          <div className="rules-list tab-panel-box" dangerouslySetInnerHTML={{ __html: rules }} />
        </div>


      </TabPanel>
    </Header>
  );
};

export default TournamentDetails;
