import { indiggTracker } from "../apis";

const eventIds = {
  production: {
    Game_Types_Page_Shown: "39475ca0-8089-48b7-a273-235e052f769e",
    Tournaments_List_Shown: "ae0778e7-41ec-4254-9bc6-2ccb2d12db34",
    Tournament_Popup_Shown: "c8d124c0-93e6-4b39-b0f7-2af269ce99b2",
    Join_Tournament_Popup_Shown: "835d41d5-49cf-4b92-a416-20e74227209f",
    Tournament_Details_Shown: "07f155d9-5576-47d3-81ff-f681b2e94024",
    Contest_List_Shown: "3c239e65-df0b-4353-b50a-f782286c676a",
    Contest_Join_Popup_Shown: "750594a5-eaa3-43a5-ac53-d7ef8ed02142",
    Contest_Share_Button_Click: "ec92cc9c-80a6-4d45-8b5d-e9664bc014eb",
    Contest_Details_Shown: "7f04ce26-4ddd-4974-b38a-c628cbdf31d2",
    Tournament_Share_Button_Click: "baecc92d-8364-479e-b695-da644ac321c0",
    Team_Registration_Shown: "ac0cbac6-2b0e-4c9f-a941-1c50a9f80ab9",
  },
  staging: {
    Game_Types_Page_Shown: "af270226-c328-44fc-a920-6a51f01891b7",
    Tournaments_List_Shown: "daac2166-0090-425f-ab0c-0faa6b9ca024",
    Tournament_Popup_Shown: "5db997db-fa80-4b64-a705-93464ca0b192",
    Join_Tournament_Popup_Shown: "884d9a79-a76b-4dab-b435-4987fc0adf6b",
    Tournament_Details_Shown: "67fcb144-1efa-4fd2-a518-ec26c55a03f5",
    Contest_List_Shown: "6e17d071-4b87-4ace-a299-c66b6019ef81",
    Contest_Join_Popup_Shown: "da04d019-40e5-47ec-b6ee-36aafa00b48d",
    Contest_Share_Button_Click: "e846f852-753a-4e62-9e24-324036c798ad",
    Contest_Details_Shown: "4a1a76e9-82f3-42b6-964e-80d198e540cb",
    Tournament_Share_Button_Click: "c2e63bd3-4635-407c-8361-994f830a3f86",
    Team_Registration_Shown: "2cb520dc-6cc0-4551-a253-a93375aad32b",
  },
};

const trackerEventId = eventIds[process.env.REACT_APP_ENV_MODE === "production" ? "production" : "staging"];
async function getEventKeyById(eventId) {
  for (const key in trackerEventId) {
    if (trackerEventId[key] === eventId) {
      return key;
    }
  }
  return null;
}
export const addTrackData = async (eventId, data) => {
  if (localStorage.getItem("profile")) {
    let userData = JSON.parse(localStorage.getItem("profile"));
    var eventKey = await getEventKeyById(eventId);
    var payload = {
      gameid:"de2b2997-e272-4922-935b-5de79c01cc82",
      eventId: eventId,
      eventName: eventKey,
      game_gameid: data?.gameCatalogId,
      gameName: data?.gameCatalogName,
      source:"Klash",
      userId:userData?.userId,
      timestamp:new Date()
    };
    if (data?.contestId) {
      // payload.contestId = data?.contestId;
    }
    if (data?.tournamentId) {
      // payload.tournamentId = data?.tournamentId;
    }

    await addTracker(eventId, payload);
  }
};

export const addTracker = async (eventId, data) => {
  console.clear();
  await indiggTracker(eventId, data);
};

export { trackerEventId };
