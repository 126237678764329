// Path: src\Component\screens\tournamentStructure.jsx
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
    Box,
    Grid,
    LinearProgress,
    Button,
    Divider,
    Tabs,
    Tab,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Tournaments from "../../../src/assets/images/Tournaments.png";
import url from "../../constants/url";
import RulesPopup from "../Popup/Rules";
import WinningBreakup from "../Popup/WinningBreakup";
import TournamentRoundPopup from "../Popup/TournamentRoundPopup";
import TournamentConfirmationPopup from "../Popup/TournamentConfirmationPopup";
import ToastPopup from "../Popup/ToastPopup";
import { useNavigate } from "react-router";
import Loader from "../loader";

import { tournamentRulesApi, tournamentPricePointPoolApi, tournamentRoundsById } from "../../apis";
import RightArrow from "../../../src/assets/images/right-arrow.png"
import ShareIcon from "../../../src/assets/images/Vector.png"
import { RWebShare } from "react-web-share";
import { formatDate, shareLink } from "../Utilities/helper";
import { addTrackData, trackerEventId } from "../../config/tracker";

const TournamentStructure = ({ tournaments }) => {
    const [isRulesPopup, setIsRulesPopup] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isIsTournamentRoundPopup, setIsTournamentRoundPopup] = useState(false);
    const [isIsTournamentConfirmationPopup, setIsTournamentConfirmationPopup] = useState(false);
    const [rules, setRules] = useState('');
    const [prizePool, setPrizePool] = useState('');
    const [rounds, setRounds] = useState([]);
    const [firstRoundId, setFirstRoundId] = useState('');
    const [uniqueIgn, setUniqueIgn] = useState('');
    const [selectedMatchDate, setSelectedMatchDate] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate();
    const [teamRegistration, setTeamRegistration] = useState(false);
    const [isLoading, setIsLoader] = useState(false);

    const totalSlots = tournaments?.totalPlayers || 0;
    const slotsFilled = tournaments?.totalJoinedPlayers || 0;
    const percentageFilled = (slotsFilled / totalSlots) * 100;
    const redFillStart = percentageFilled; // Start red fill after the percentageFilled

    const redFillWidth = tournaments?.totalJoinedPlayers >= tournaments?.minimumSlot ? 0 : ((tournaments?.minimumSlot || 0) / totalSlots) * 100; // Width of the red fill
    const remainingPlayers = tournaments?.totalPlayers - tournaments?.totalJoinedPlayers;

    async function fetchRulesData() {
        try {
            setIsLoader(true);
            let response = await tournamentRulesApi(tournaments?._id);
            if (response?.success) {
                setIsLoader(false);
                setRules(response?.item?.rules);
            }
            else {
                setIsLoader(false);
                setRules('');
            }
            setIsRulesPopup(true)
        } catch (error) {
            setIsLoader(false);
            setRules('');
            console.log("getTournament error");
        }
    }

    async function fetchPricePointPoolData() {
        setIsLoader(true);
        try {
            let response = await tournamentPricePointPoolApi(tournaments?._id);
            if (response?.success) {
                setIsLoader(false);
                setPrizePool(response?.data);
            }
            else {
                setIsLoader(false);
                setPrizePool('');
            }
            setIsPopupOpen(true)
        } catch (error) {
            setIsLoader(false);
            setPrizePool('');
            console.log("getTournament error");
        }
    }

    const getjoin = async () => {
        setIsLoader(true);
        try {
            let response = await tournamentRoundsById(tournaments?._id);
            if (response?.success) {
                setIsLoader(false);
                setRounds(response);
                setIsTournamentRoundPopup(true)
            }
            else {
                setIsLoader(false);
                setRounds([]);
            }
        } catch (error) {
            setIsLoader(false);
            setRounds([]);
            console.log("getTournament error");
        }
    }

    return (
        <>
            {isLoading ? <Loader /> : <></>}

            <Box className="newtournamentbox-withshare--wrap">
                <div className="newtournament-list-title-bar--withshare mt-3">
                    <div className="newtournament-list-title">

                    {tournaments?.title?.length > 18 ? <marquee>{tournaments?.title}</marquee> : tournaments?.title}

                    </div>

                    <div className="newtournamentbox-btn-list--wrap">
                        <div className="newtournamentbox-list-status-withshare"   onClick={() => fetchRulesData()}>
                            View Rules
                        </div>
                        <div onClick={()=>{
                          addTrackData(trackerEventId?.Tournament_Share_Button_Click, { gameCatalogName: `${tournaments?.gameAry?.name}`, gameCatalogId: `${tournaments?.gameAry?._id}`, tournamentId: `${tournaments?._id}` });
                        }}>
                        <RWebShare
                            data={shareLink(localStorage?.getItem('game'), null, null, tournaments?._id, 'tournament')}
                            sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
                            onClick={() => {
                                console.log("shared successfully!")
                                
                            }}
                        >
                            <div

                                className="newtournament-share-button"


                            >

                                Share
                                <img className="newtournament-share-icon1" src={ShareIcon} alt=""></img>
                            </div>
                        </RWebShare>
                        </div>
                    </div>
                </div>


                <div className="row newcontest-basicdetails">
                    <div className="row newtournament-basicdetails">
                        <div className="col-4 newtournament-image-wrapper">
                            <div className="newtournament-image-container">
                                {tournaments?.featuredImage?.default ? (
                                    <img src={url.image_url + tournaments?.featuredImage?.default} alt="" className="newtournament-image" />
                                ) : (
                                    <img src={Tournaments} alt="" className="newtournament-image" />
                                )}
                                <span className="newtournament-image-image-text">{tournaments?.gameTypeAry?.name}</span>
                            </div>
                        </div>
                        <div className="col-8 newtournament-details--wrap ps-2">

                            <div className="newcontest">
                                <div className="newcontestbox-list-label">Date</div>
                                <div className="newcontestbox-list-value">
                                    <span className="value-wrapper"> {formatDate(tournaments?.startDate)}</span>
                                </div>
                            </div>
                            <div className="newcontest">
                                <div className="newcontestbox-list-label">Rounds</div>
                                <div className="newcontestbox-list-value">
                                    <span className="value-wrapper">{tournaments?.roundsCount}</span>
                                </div>
                            </div>
                            <div className="newcontest">
                                <div className="newcontestbox-list-label">ID</div>
                                <div className="newcontestbox-list-value">
                                    <span className="value-wrapper"
                                        onClick={() => {
                                            navigator.clipboard.writeText(tournaments?.shortCode);
                                        }}>{tournaments?.shortCode}</span>
                                </div>
                            </div>
                            <div className="newtournament--wrap">
                                {tournaments?.titles?.[0]?.isSelection && tournaments?.titles?.[0]?.name && tournaments?.titles?.[0]?.value &&
                                    <div className="newcontest">
                                        <div className="newcontestbox-list-label">{tournaments?.titles?.[0]?.name}</div>
                                        <div className="newcontestbox-list-value">

                                            <span className="value-wrapper truncate-map-box"> {tournaments?.titles?.[0]?.value}</span>
                                        </div>
                                    </div>
                                }
                                {tournaments?.titles?.[1]?.isSelection && tournaments?.titles?.[1]?.name && tournaments?.titles?.[1]?.value &&
                                    <div className="newcontest">
                                        <div className="newcontestbox-list-label">{tournaments?.titles?.[1]?.name}</div>
                                        <div className="newcontestbox-list-value">
                                            <span className="value-wrapper truncate-map-box">{tournaments?.titles?.[1]?.value}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="newtournament-prizedetails">
                        <div className="newcontest-prize--wrap">
                            <div className="newcontest">

                                <div className="newcontestbox-list-label">Prize Pool</div>
                                <div className="newcontestbox-list-value-icon">
                                    {
                                        tournaments?.prizePoolType === 'amount' &&
                                        <span>
                                            <img className="newcontest-list-currency-icon1" 
                                           src={
                                            url?.image_url +
                                            tournaments?.currency?.[0]?.outCurrency?.img?.default
                                        }
                                                alt="" />
                                        </span>
                                    }
                                    <span className="newcontest-list-currency-amount value-wrapper">  {tournaments?.prizePoolType === 'amount' ? tournaments?.prizePool : tournaments?.rewardDisplayText}</span>
                                </div>
                            </div>
                            <div className="newcontest">
                                <div className="newcontestbox-list-label">Winners</div>
                                <div className="newcontestbox-list-value-icon-dropdown">
                                    <div className="newcontest-list-currency-amount value-wrapper">
                                        {tournaments?.totalWinners}
                                    </div>
                                    <span className="newcontest-list-currency-amount value-wrapper" onClick={() => fetchPricePointPoolData()}>
                                        <img className="newcontest-list-currency-icon1" src={RightArrow} alt="" style={{ marginRight: '3px' }} />

                                    </span>


                                </div>
                            </div>
                        </div>
                        <div className="newcontest">
                            <div className="newcontestbox-list-label">Join Using</div>
                            <div className="newcontestbox-list-value-icon">
                                {tournaments?.entryFee > 0 ? (
                                    <span className="newcontest-list-currency-amount value-wrapper">
                                        <img className="newcontest-list-currency-icon1"  src={
                                        url.image_url +
                                        tournaments?.currency?.[0]?.inCurrency?.img?.default
                                    } alt="" /> {tournaments?.entryFee > 0 ? tournaments?.entryFee : "Free"}
                                    </span>
                                ) : (
                                    <span className="newcontest-list-currency-amount value-wrapper">
                                        Free
                                        </span>
                                )}

                            </div>
                        </div>
                    </div>
                    <Grid container spacing={2} className="details-grid slots-info">
                        <Grid item xs={12} className="details-grid-box">
                            <div className="progress-bar-wrapper custome-progressbar">
                                <LinearProgress
                                    variant="determinate"
                                    value={percentageFilled}
                                    className="custom-linear-progress"
                                />
                                <Tooltip
                                    title={`Minimum ${tournaments?.minimumSlot || 0} ${tournaments?.minimumSlot>1?'slots':'slot'} Required`}
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                        sx: {
                                            "& .MuiTooltip-tooltip": {
                                              backgroundColor: "#1F3338",
                                              border: "1px solid #87A7A6",
                                              color: "#86A6A6", // Text color
                                              fontSize: "0.75rem",
                                              filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                                              borderRadius: "4px",
                                              padding: "8px",
                                              width: "162px",
                                              height: "50.758px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            },
                                            "& .MuiTooltip-arrow": {
                                              color: "#1F3338", // Arrow background color
                                              "&::before": {
                                                boxShadow: "0 0 0 1px #86A6A6", // Simulating white border
                                              },
                                            },
                                          },
                                        }}
                                    classes={{ tooltip: "custom-tooltip" }}>
                                    <div
                                        className="progress-bar-red-fill"
                                        style={{
                                            width: `${redFillWidth}%`,
                                            left: `${percentageFilled}%`,
                                            position: "absolute",
                                            height: "100%",
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xs={6} className="details-grid-box">
                            <div className="slots-remaining">{tournaments?.totalPlayers - tournaments?.totalJoinedPlayers} {((tournaments?.totalPlayers - tournaments?.totalJoinedPlayers)>1) ?"slots":"slot" } remaining</div>
                        </Grid>
                        <Grid item xs={6} textAlign="right" className="details-grid-box">
                            <div className="slots-filled">{tournaments?.totalJoinedPlayers} {tournaments?.totalJoinedPlayers>1 ? "slots" : 'slot'} filled</div>
                        </Grid>
                    </Grid>

                </div>
                <div className="newcontest-registerjoined-container">
                    {tournaments?.isJoined == false && remainingPlayers > 0 ? (
                        <div
                            className="newcontest-regiter-container"

                        >
                            <Grid container spacing={2} className="details-grid slots-info">
                                <Grid
                                    item
                                    xs={6}
                                    textAlign="center"
                                    className="details-grid-box"
                                >
                                    <div className="newcontest-tournamentregbtn" onClick={() => getjoin()}>REGISTER</div>
                                </Grid>
                            </Grid>
                        </div>
                    ) : tournaments?.isJoined === false && remainingPlayers === 0 ? (
                        <div className="newcontest-regiter-container">
                            <Grid container spacing={2} className="details-grid slots-info">
                                <Grid
                                    item
                                    xs={6}
                                    textAlign="center"
                                    className="details-grid-box"
                                >
                                    <div className="newcontest-tournamentregbtn">FULL</div>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <div
                            className="newcontest-regiter-container-joined"

                        >
                            <Grid container className="details-grid slots-info">
                                <Grid
                                    item
                                    xs={12}
                                    textAlign="center"
                                    className="details-grid-box"
                                >
                                    <div className="newcontest-tournamentregbtnjoined" onClick={() => navigate("/tournament/details/" + tournaments?._id)}>JOINED</div>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>
            </Box>

            {isRulesPopup &&
                <RulesPopup
                    isOpen={isRulesPopup}
                    title="Rules"
                    onClose={() => setIsRulesPopup(false)}
                    rules={rules}
                />
            }

            {isPopupOpen &&
                <WinningBreakup
                    isOpen={isPopupOpen}
                    title="Winning Breakup"
                    onClose={() => setIsPopupOpen(false)}
                    prizePool={prizePool}
                    tournaments={tournaments}
                />
            }

            {isIsTournamentRoundPopup &&
                <TournamentRoundPopup
                    isOpen={isIsTournamentRoundPopup}
                    title={tournaments?.title}
                    onClose={() => setIsTournamentRoundPopup(false)}
                    tournaments={tournaments}
                    rounds={rounds}
                    firstRoundId={firstRoundId}
                    setFirstRoundId={setFirstRoundId}
                    uniqueIgn={uniqueIgn}
                    setUniqueIgn={setUniqueIgn}
                    setIsTournamentRoundPopup={setIsTournamentRoundPopup}
                    setIsTournamentConfirmationPopup={setIsTournamentConfirmationPopup}
                    setSelectedMatchDate={setSelectedMatchDate}
                    setErrorMsg={setErrorMsg}
                    setShowToast={setShowToast}
                />
            }

            {isIsTournamentConfirmationPopup &&
                <TournamentConfirmationPopup
                    isOpen={isIsTournamentConfirmationPopup}
                    title={tournaments?.title}
                    onClose={() => setIsTournamentConfirmationPopup(false)}
                    tournaments={tournaments}
                    selectedMatchDate={selectedMatchDate}
                    firstRoundId={firstRoundId}
                    uniqueIgn={uniqueIgn}
                    setIsTournamentConfirmationPopup={setIsTournamentConfirmationPopup}
                    setErrorMsg={setErrorMsg}
                    setShowToast={setShowToast}
                />
            }

            {showToast && (
                <ToastPopup message={errorMsg} setShowToast={setShowToast} />
            )}
        </>
    )
}

export default TournamentStructure;