const url = {
    saas_api_url: 'https://indigg-api-by-eaas-production.gamerji.cloud/api/',
    indigg_api_url: 'https://indigg-api-production.gamerji.cloud/api/',
    klash_redirect_url: 'https://kgen.io/gamer/klash',
    image_url: 'https://gamerji-uploads.s3.amazonaws.com/',
    redirect_url: 'https://indigg-pwa-staging.gamerji.cloud/',
    deepLink: (link) => `https://kgen.io/gamer/klash?showLoginModal=true&${link}`,
    trackerUrl: (eventId) => `https://prod-platformbase-apigw.indi.gg/gamerji-assist/events/${eventId}/drop`
}
//add comment and build

export default url;