import React, { Fragment, useEffect, useState } from "react";
import Header from "../../Component/Home/header";
import { gameTypeList, login, refreshTokenApi } from "../../apis";
import url from "../../constants/url";
import { useNavigate } from "react-router";
import Loader from "../loader";
import { NotDataFound, PageNotFound } from "../screens/Pagenotfound";
import { addTrackData, trackerEventId } from "../../config/tracker";
function GameList() {
  const [game, setGame] = useState({});
  const [gameTypeListArray, setGameTypeListArray] = useState([]);
  const [isTournament, setIsTournament] = useState(false);
  const [tournamentImage, setTournamentImage] = useState("");
  const [noData, setNoData] = useState(true);
  const [isGameExist, setIsGameExist] = useState(false);
  const [isLoading, setIsLoader] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const gameId = urlSearchParams.get("gameId");

    if (gameId != undefined && gameId != "undefined" && gameId != null && gameId != "null" && gameId != "") {
      let game = gameId;
      fetchData(game);
    } else if (localStorage.getItem("game")) {
      let game = localStorage.getItem("game");
      fetchData(game);
    } else {
      setNoData(true);
      setGameTypeListArray([]);
      setIsTournament(false);
    }
  }, []);

  useEffect(() => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);

      console.log(urlSearchParams);
      const urlToken = urlSearchParams.get("accessToken");
      const urlRefreshToken = urlSearchParams.get("refreshToken");
      const gameId = urlSearchParams.get("gameId");
      const gameTypeId = urlSearchParams.get("gameTypeId");
      const gameTypeName = urlSearchParams.get("gameTypeName");
      const typeOfRedirect = urlSearchParams.get("type");

      if (urlToken != undefined && urlToken != "undefined" && urlToken != null && urlToken != "null" && urlToken != "") {
        localStorage.setItem("accessToken", urlToken);
        fetchUserData();
      } else if (
        !(urlToken != undefined && urlToken != "undefined" && urlToken != null && urlToken != "null" && urlToken != "") &&
        !localStorage?.getItem("accessToken")
      ) {
        handleBackClick();
      }

      if (
        urlRefreshToken != undefined &&
        urlRefreshToken != "undefined" &&
        urlRefreshToken != null &&
        urlRefreshToken != "null" &&
        urlRefreshToken != ""
      ) {
        localStorage.setItem("refreshToken", urlRefreshToken);
      }

      if (gameId != undefined && gameId != "undefined" && gameId != null && gameId != "null" && gameId != "") {
        localStorage.setItem("game", gameId);
      }

      if (
        typeOfRedirect != undefined &&
        typeOfRedirect != "undefined" &&
        typeOfRedirect != null &&
        typeOfRedirect != "null" &&
        typeOfRedirect != ""
      ) {
        redirectToPath(gameTypeId, gameTypeName, typeOfRedirect);
      }
    } catch (e) { }
  }, []);

  const redirectToPath = async (gameType, gameTypeName, type) => {
    if (localStorage?.getItem("accessToken")) {
      if (type === "contest") {
        localStorage.setItem("selectedGameMode", gameTypeName);
        navigate("/contest/list/" + gameType);
      } else {
        navigate("/tournament/list");
      }
    } else {
      handleBackClick();
    }
  };

  const fetchUserData = async (retry = true) => {
    try {
      setIsLoader(true);
      let res = await login();
      if (res?.success) {
        setIsLoader(false);
        localStorage.setItem("profile", JSON.stringify(res?.data));
        removeParamsFromUrl(["accessToken", "refreshToken", "gameId"]);
        // if (!(gameTypeListArray?.length > 0)) {
        //   fetchData();
        // }
      } else if (res?.status === 401 && retry) {
        setIsLoader(false);
        await refreshTokenApiCall(false);
        fetchUserData(false);
      }
    } catch (e) {
      setIsLoader(false);
    }
  };

  const refreshTokenApiCall = async (retry = true) => {
    try {
      setIsLoader(true);
      let res = await refreshTokenApi();
      if (res?.success) {
        setIsLoader(false);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.setItem("accessToken", res?.data?.accessToken);
        localStorage.setItem("refreshToken", res?.data?.refreshToken);
        if (retry) {
          setIsLoader(false);
          setInterval(fetchUserData(false), 5000);
        }
      } else {
        setIsLoader(false);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        handleBackClick();
      }
    } catch (e) {
      setIsLoader(false);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      handleBackClick();
    }
  };

  async function fetchData(game) {
    try {
      setIsLoader(true);
      let payload = {
        search: {
          gameId: game,
        },
        pagination: {
          sortby: "order",
          sort: "asc",
        },
      };

      let response = await gameTypeList(payload);
      console.log(response);

      if (response?.success) {
        setIsLoader(false);
        setGameTypeListArray(response?.data);
        setGame(response?.game);
        localStorage.setItem("selectedGameName",response?.game?.name);
        setIsTournament(response?.isTournament);
        setTournamentImage(response?.tournamentImage);
        if (!response?.data?.length > 0 && !response?.isTournament) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        if (response?.isGameExist) {
          addTrackData(trackerEventId?.Game_Types_Page_Shown, {
            gameCatalogName: `${response?.game?.name}`,
            gameCatalogId: `${response?.game?._id}`,
          });
        }
        setIsGameExist(response?.isGameExist);
      } else {
        setIsLoader(false);
        setGameTypeListArray([]);
        setIsTournament(false);
        setTournamentImage("");
        setNoData(true);
      }
    } catch (e) {
      setIsLoader(false);
    }
  }

  const handleBackClick = () => {
    window.location.href = `${url?.klash_redirect_url}`;
  };

  function removeParamsFromUrl(paramsToRemove) {
    let url = new URL(window.location.href);
    let params = url.searchParams;
    paramsToRemove.forEach((param) => params.delete(param));
    let updatedQueryString = params.toString();
    let newUrl = url.origin + url.pathname + (updatedQueryString ? "?" + updatedQueryString : "");
    window.history.replaceState(null, "", newUrl);
  }

  return (
    // <div className="h-100 bgmi-container"  style={{
    //   background: "radial-gradient(circle at center, #354F56 0%, #1F3338 82.72%)"
    // }}>

    <Header title={game?.name || "-"} onBackClick={handleBackClick} background={true}>
      {isLoading ? (
        <Loader />
      ) : isGameExist === true ? (
        <div className="modes-section">
          {!noData && isGameExist === true && (
            <>
              <div className="modes-header" style={{ marginTop: "31px" }}>
                GAME MODES
              </div>
              <div className="game-modes-grid">
                {isTournament && (
                  <div
                    className="game-mode-card"
                    onClick={() => {
                      navigate("/tournament/list");
                    }}>
                    <div className="game-mode-card-img">
                      <img src={url?.image_url + tournamentImage?.default} alt={"tournament"} className="game-mode-img" />
                    </div>
                    <div className="game-mode-title">{"Tournaments"}</div>
                  </div>
                )}
                {gameTypeListArray.map((mode) => (
                  <>
                    <div
                      className="game-mode-card"
                      onClick={() => {
                        localStorage.setItem("selectedGameMode", mode?.name);
                        navigate("/contest/list/" + mode?._id);
                      }}>
                      <div className="game-mode-card-img">
                        <img src={url?.image_url + mode?.displayImage?.default} alt={mode?.name} className="game-mode-img" />
                      </div>
                      <div className="game-mode-title">{mode?.name?.toLowerCase()}</div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
          {noData && <NotDataFound msg={"No games found"} />}
          <button
            className="game-mode-button my-contest-button mt-3"
            onClick={() => {
              localStorage.setItem("selectedGameName", game?.name);
              navigate("/my-contest/list");
            }}>
            MY CONTEST
          </button>
        </div>
      ) : (
        <PageNotFound />
      )}
    </Header>
  );
}

export default GameList;
