// Path: src\Component\screens\widgets\myContestStructure.js
import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Button, capitalize } from "@mui/material";
import { ReactComponent as Clipcopy } from "../../../../src/assets/images/clipcopy.svg";

import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import url from "../../../constants/url";
import WinningBreakup from "../../Popup/WinningBreakup";
import Layer_1 from "../../../../src/assets/images/Layer_1.png";
import Loader from "../../loader";
import { formatDate, formatTime } from "../../Utilities/helper";
import RightArrow from "../../../assets/images/right-arrow.png";
const MyContestStructure = ({ handleContestClick, contest }) => {
  const [isPricePointPoolModel, setPricePointPool] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoader] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const totalSlots = contest?.contestData.totalPlayers;
  const slotsFilled = contest?.contestData.totalJoinedPlayers;
  const percentageFilled = (slotsFilled / totalSlots) * 100;
  const redFillWidth =
    contest?.contestData.totalJoinedPlayers >= contest?.contestData?.minimumSlot ? 0 : ((contest?.contestData?.minimumSlot || 0) / totalSlots) * 100;

  //Handle Card Click this way due to event propogation issue
  const [clicked, setClicked] = useState(false);
  const handleContestClickPoint = () => {
    setClicked(true);
    setSelectedItem(contest?.contestData);
    setPricePointPool(true);
  };
  const handleContest = () => {
    if (!clicked) {
      handleContestClick(contest?.contestData);
    }
    setClicked(false);
  };
  return (
    <>
      <div className="newcontestbox-list" onClick={() => handleContest()}>
        <div className="newcontest-list-title-bar mt-3">
          <div className="newcontestbox-list-title">
            {contest?.contestData?.title?.length > 30 ? <marquee>{contest?.contestData?.title}</marquee> : contest?.contestData?.title}
            {/* <marquee>erjfjerbg efjknerjkgfnerk fjkernjfknkjenfjker</marquee> */}
          </div>

          <div className="newcontestbox-list-status">{capitalize(contest?.contestData?.status)}</div>
        </div>

        {/* basic details */}
        <div className="row newcontest-basicdetails">
          <div className="newcontest--wrap">
            <div className="newcontest">
              <div className="newcontestbox-list-label">Date</div>
              <div className="newcontestbox-list-value">
                <span className="value-wrapper"> {formatDate(contest?.contestData?.date)}</span>
              </div>
            </div>
            <div className="newcontest">
              <div className="newcontestbox-list-label">Time</div>
              <div className="newcontestbox-list-value">
                <span className="value-wrapper"> {formatTime(contest?.contestData?.time)}</span>
              </div>
            </div>
            {contest?.contestData?.titles?.[0]?.isSelection &&
              contest?.contestData?.titles?.[0]?.name &&
              contest?.contestData?.titles?.[0]?.value && (
                <div className="newcontest">
                  <div className="newcontestbox-list-label">{contest?.contestData?.titles?.[0]?.name}</div>
                  <div className="newcontestbox-list-value truncate">
                    <span className="value-wrapper truncate-map-box"> {contest?.contestData?.titles?.[0]?.value}</span>
                  </div>
                </div>
              )}
            {contest?.contestData?.titles?.[1]?.isSelection &&
              contest?.contestData?.titles?.[1]?.name &&
              contest?.contestData?.titles?.[1]?.value && (
                <div className="newcontest">
                  <div className="newcontestbox-list-label">{contest?.contestData?.titles?.[1]?.name}</div>
                  <div className="newcontestbox-list-value">
                    <span className="value-wrapper truncate-map-box"> {contest?.contestData?.titles?.[1]?.value}</span>
                  </div>
                </div>
              )}
          </div>

          <div className="row">
            <div className="col-6">
              <div className="newcontestprize--wrap">
                <div className="newcontest">
                  <div className="newcontestbox-list-label">Prize Pool</div>
                  <div className="newcontestbox-list-value-icon">
                    <span>
                      <img
                        className="newcontest-list-currency-icon1"
                        src={url?.image_url + contest?.contestData?.currency?.[0]?.outCurrency?.img?.default}
                      />
                    </span>
                    <span className="newcontest-list-currency-amount value-wrapper">{contest?.contestData?.prizePool}</span>
                  </div>
                </div>
                <div className="newcontest">
                  <div className="newcontestbox-list-label">{contest?.contestData?.winningModel == "perKill" ? "Per Kill" : "Winners"}</div>
                  <div className="newcontestbox-list-value-icon-dropdown">
                    {contest?.contestData?.winningModel === "perKill" ? (
                      <span className="newcontest-list-currency-amount value-wrapper">
                        {" "}
                        <img
                          className="newcontest-list-currency-icon1"
                          src={url?.image_url + contest?.contestData?.currency?.[0]?.outCurrency?.img?.default}
                          alt=""
                        />
                      </span>
                    ) : null}
                    <span className="newcontest-list-currency-amount value-wrapper">
                      {contest?.contestData?.winningModel === "perKill" ? contest?.contestData?.killPoints : contest?.contestData?.totalWinners}
                    </span>
                    {contest?.contestData?.winningModel != "perKill" && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleContestClickPoint();
                        }}>
                        <img className="newcontest-list-currency-icon" src={RightArrow} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <div className="newcontest">
                <div className="newcontestbox-list-label">Join Using</div>
                <div className="newcontestbox-list-value-icon">
                  {contest?.contestData?.entryFee > 0 ? (
                    <span>
                      <img
                        className="newcontest-list-currency-icon1"
                        src={url.image_url + contest?.contestData?.currency?.[0]?.inCurrency?.img?.default}
                        alt=""
                      />
                    </span>
                  ) : null}
                  <span className="newcontest-list-currency-amount value-wrapper">
                    {" "}
                    {contest?.contestData?.entryFee > 0 ? contest?.contestData?.entryFee : "Free"}
                  </span>
                </div>
              </div>
            </div>
            <Grid container spacing={2} className="details-grid slots-info">
              <Grid item xs={12} className="details-grid-box">
                <div className="progress-bar-wrapper mt-3 custome-progressbar">  {/* custome class for progresss bar */}
                  <LinearProgress variant="determinate" value={percentageFilled || 0} className="custom-linear-progress" />

                  <Tooltip
                    title={`Minimum ${contest?.contestData?.minimumSlot || 0} ${contest?.contestData?.minimumSlot>1?'slots':'slot'} Required`}
                    arrow
                    placement="top"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "#1F3338",
                          border: "1px solid #87A7A6",
                          color: "#86A6A6", // Text color
                          fontSize: "0.75rem",
                          filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                          borderRadius: "4px",
                          padding: "8px",
                          width: "162px",
                          height: "50.758px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        "& .MuiTooltip-arrow": {
                          color: "#1F3338", // Arrow background color
                          "&::before": {
                            boxShadow: "0 0 0 1px #86A6A6", // Simulating white border
                          },
                        },
                      },
                    }}
                    classes={{ tooltip: "custom-tooltip" }}
                    open={tooltipOpen}
                    disableHoverListener>
                    <div
                      className="progress-bar-red-fill"
                      style={{
                        width: `${redFillWidth || 0}%`,
                        left: `${percentageFilled || 0}%`,
                        position: "absolute",
                        height: "100%",
                      }}
                      onClick={() => setTooltipOpen(!tooltipOpen)}
                      onMouseEnter={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                    />
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={6} className="details-grid-box mt-1">
                <div className="newcontestslots-remaining">
                  {contest?.contestData?.totalPlayers - contest?.contestData?.totalJoinedPlayers} {(contest?.contestData?.totalPlayers - contest?.contestData?.totalJoinedPlayers)>1 ? 'slots':'slot'} remaining
                </div>
              </Grid>
              <Grid item xs={6} textAlign="right" className="details-grid-box mt-1">
                <div className="newcontestslots-filled">{contest?.contestData?.totalJoinedPlayers} {contest?.contestData?.totalJoinedPlayers>1?'slots':'slot'} filled</div>
              </Grid>
            </Grid>
            <div className="newcontest-roomidpass-container">
              <Grid container spacing={2} className="details-grid slots-info">
                <Grid
                  item
                  xs={6}
                  textAlign="center"
                  className="details-grid-box"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(contest?.contestData?.roomId);
                  }}>
                  <div className="newcontest-room-id-label newcontest-details-label">Room ID</div>
                  <div className="newcontest-room-id-value">
                    {contest?.contestData?.roomId !== "" ? (
                      <>
                        {" "}
                        {contest?.contestData?.roomId?.length > 15 ? (
                          <marquee> {contest?.contestData?.roomId}</marquee>
                        ) : (
                          contest?.contestData?.roomId
                        )}{" "}
                        <span>
                          <Clipcopy />
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  textAlign="center"
                  className="details-grid-box details-grid-box-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(contest?.contestData?.roomPassword);
                  }}>
                  <div className="newcontest-password-label newcontest-details-label">Password</div>
                  <div className="newcontest-room-id-value">
                    {contest?.contestData?.roomPassword !== "" ? (
                      <>
                        {contest?.contestData?.roomPassword?.length > 15 ? (
                          <marquee> {contest?.contestData?.roomPassword}</marquee>
                        ) : (
                          contest?.contestData?.roomPassword
                        )}{" "}
                        <span>
                          <Clipcopy />
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      {isPricePointPoolModel && (
        <WinningBreakup
          isOpen={isPricePointPoolModel}
          title="Winning Breakup"
          onClose={() => setPricePointPool(false)}
          prizePool={selectedItem}
          tournaments={selectedItem}
        />
      )}
    </>
  );
};

export default MyContestStructure;
